<template>
  <div class="footer">
    <footer class="footer__content page-footer font-small unique-color-dark">
      <div class="footer__content__span footer-copyright text-center py-3">
        © {{ new Date().getFullYear() }} <b>developed</b> by
        <a href="http://guane.com.co/" target="_blank"> guane.com.co</a>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.footer__content {
  background-color: $color-background-dark;
  color: $color-light;
  padding-top: 8px;
  &__span {
    a {
      color: $color-primary-company;
    }
  }
}
</style>
